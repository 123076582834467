* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f8f9fa;
}

.sideBar.active {
  width: 300px;
}

.sideBar {
  position: fixed;
  width: 70px;
  background-color: #1D3557;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  transition: all 0.25s ease-in-out;
}

.sideBar.active~.pages {
  left: 300px;
  width: calc(100% - 300px);

}

.pages {
  position: absolute;
  left: 70px;
  height: 100%;
  width: calc(100% - 70px);
  /* width: calc(100% - 80px); */
  transition: all 0.25s ease-in;
}

.get_heading {
  margin: 10px 50px;
  font-size: 1.4rem;
}

.profile_card {
  margin: 2px 20px;
  background-color: #fff;
  /* border: 1px solid #34383d; */
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 2px 1px 13px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 1px 13px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 1px 13px -4px rgba(0, 0, 0, 0.75);
  /* height: auto;
  width: 90%; */
}

.svg_icon {
  color: #fff;
}

.menuItem {
  color: #fff !important;
}

.sideBar.active .selected {
  background-color: #fff;
  border-radius: 10px;
  color: #571d2a !important;
  text-decoration: none;
  padding-left: 0.5rem;
}

.sideBar.not_Active .selected {
  background-color: white;
  width: 45px;
  margin-left: -0.7rem !important;
  height: 45px;
  border-radius: 50px;
  padding-left: 0.7rem;
  padding-top: 0.3rem;


}

.MuiOutlinedInput-notchedOutline {
  border-color: red;
}

.selected .menuItem {
  color: #1D3557 !important;
}

.selected .svg_icon {
  color: #1D3557;
}

.card_separater {
  margin-top: 30px;
}

.Profile_heading {
  font-size: 2rem;
}

.status_profile {
  font-size: 1.3rem;
}

.extra_margin {
  margin: auto 140px;
}

.ant-picker-dropdown {
  z-index: 1400 !important;
}

.ant-picker-range {
  width: 100% !important;
  padding: 15px !important;
  margin-bottom: 20px !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.ant-picker-range:hover {
  border: 1px solid #000;
}

@media (max-width:991px) {
  .Profile_heading {
    font-size: 1.5rem;
  }

  .get_heading {
    font-size: 1rem;
    margin: 5px 5px;
  }

  .status_profile {
    font-size: 1rem;
  }

  .extra_margin {
    margin: auto 0px;
  }
}


@media(max-width:767px) {
  .Profile_heading {
    font-size: 1rem;
  }

  .get_heading {
    font-size: 0.8rem;
  }

  .status_profile {
    font-size: .8rem;
  }
}



/* ::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent
} */


/* Change Password */
.ch_pass_field {
  margin-bottom: 8px;
  width: 100%;
  position: relative;
}

.ch_pass_field label {
  width: 100%;
}

.ch_pass_field span {
  font-size: 18px;
  font-weight: 500;
  color: #1D3557;
  display: block;
}

.ch_pass_field input {
  padding: 8px 40px 8px 12px;
  margin-top: 8px;
  border-radius: 6px;
  min-height: 45px;
  width: 100%;
  border: 1px solid #ededed;
}

.ch_pass_field .eye-ic {
  position: absolute;
  top: 43px;
  right: 10px;
  cursor: pointer;
}