input[type='date']{
background-color: "#7997c7";
padding: 15px;
font-size: 18px;
color: '#fff';
border: none;
outline: none;
border-radius: 5px;
}
::-webkit-calendar-picker-indicator{
    background-color: '#fff';
    padding: 5px;
    cursor: pointer;
}